import React from "react";
import { Link } from "react-router-dom";
import "../styles/accueil.css";
import "../components/HistorySection";
import Carousel from "../components/Carousel";
import BandeauAccueil from "../components/BandeauAccueil";
import HistorySection from "../components/HistorySection";
import BarreSeRecherche from "../components/BarreDeRecherche";

const Accueil = () => {
  const members = 3800;
  const years = 63;
  const sections = 27;

  return (
    <div>
      <div>
        <BandeauAccueil></BandeauAccueil>
      </div>

      <div>
        <Carousel></Carousel>
      </div>
      <div>
        <BarreSeRecherche></BarreSeRecherche>
      </div>
      <div className="bouton">
        <button className="bouton-accueil">
          <Link to="/evenements">
            <h4 className="h4-button">VOIR TOUS LES EVENEMENTS</h4>
          </Link>
        </button>
      </div>

      <div>
        <HistorySection years={years} members={members} sections={sections} />
      </div>
      <div className="albums">
        <div className="bouton-espace-bouton">
          <button className="bouton-accueil">
            <Link to="/albums">
              <h4 className="h4-button">VOIR NOS ALBUMS</h4>
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Accueil;
