import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/carousel.css";
import ImageAikido from "../images/Sports/banniere-aikido.webp";
import ImageAthletisme from "../images/Sports/banniere-athletisme.webp";
import ImageBadminton from "../images/Sports/banniere-badminton.webp";
import ImageBasketball from "../images/Sports/banniere-basketball.webp";
import ImageBoxeThai from "../images/Sports/banniere-boxethailandaise.webp";
import ImageCapoeira from "../images/Sports/banniere-capoeira.webp";
import ImageDanse from "../images/Sports/banniere-ecoleDeDanse.webp";
import ImageSports from "../images/Sports/banniere-ecoleDesSports.webp";
import ImageFitness from "../images/Sports/banniere-fitness.webp";
import ImageFoot from "../images/Sports/banniere-football.webp";
import ImageGymnastiqueArtistique from "../images/Sports/banniere-gymArtistique.webp";
import ImageGymnastiqueRythmique from "../images/Sports/banniere-gymRythmique.webp";
import ImageHandball from "../images/Sports/banniere-handball.webp";
import ImageJudoJuJitsu from "../images/Sports/banniere-jujitsu.webp";
import ImageKarate from "../images/Sports/banniere-karate.webp";
import ImageKungFu from "../images/Sports/banniere-kungFu.webp";
import ImageNatation from "../images/Sports/banniere-natation.webp";
import ImageParkour from "../images/Sports/banniere-parkour.webp";
import ImageRugby from "../images/Sports/banniere-rugby.webp";
import ImageSquash from "../images/Sports/banniere-squash.webp";
import ImageTaeKwonDo from "../images/Sports/banniere-taekwondo.webp";
import ImageTennis from "../images/Sports/banniere-tennis.webp";
import ImageTennisDeTable from "../images/Sports/banniere-tennisDeTable.webp";
import ImageVolleyball from "../images/Sports/banniere-volleyball.webp";
import ImageYoga from "../images/Sports/banniere-yoga.webp";
import ImageEscrime from "../images/Sports/banniere-escrime.webp";

const sports = [
  { name: "Aikido", image: ImageAikido, path: "/aikido" },
  { name: "Athletisme", image: ImageAthletisme, path: "/athletisme" },
  { name: "Badminton", image: ImageBadminton, path: "/badminton" },
  { name: "Basketball", image: ImageBasketball, path: "/basketball" },
  {
    name: "Boxe Thailandaise",
    image: ImageBoxeThai,
    path: "/boxethailandaise",
  },
  { name: "Capoeira", image: ImageCapoeira, path: "/capoeira" },
  { name: "Ecole De Danse", image: ImageDanse, path: "/ecolededanse" },
  { name: "Ecole Des Sports", image: ImageSports, path: "/ecoledessports" },
  {
    name: "Escrime-Sabre Laser",
    image: ImageEscrime,
    path: "/escrimesabrelaser",
  },
  { name: "Fitness Gym Bien-être", image: ImageFitness, path: "/fitness" },
  { name: "Football", image: ImageFoot, path: "/football" },
  {
    name: "Gymnastique Artistique",
    image: ImageGymnastiqueArtistique,
    path: "/gymnastiqueartistique",
  },
  {
    name: "Gymnastique Rythmique",
    image: ImageGymnastiqueRythmique,
    path: "/gymnastiquerythmique",
  },
  { name: "Handball", image: ImageHandball, path: "/handball" },
  { name: "Judo-Ju Jitsu", image: ImageJudoJuJitsu, path: "/judojujitsu" },
  { name: "Karate", image: ImageKarate, path: "/karate" },
  { name: "Kung Fu", image: ImageKungFu, path: "/kungfu" },
  { name: "Natation", image: ImageNatation, path: "/natation" },
  { name: "Parkour", image: ImageParkour, path: "/parkour" },
  { name: "Rugby", image: ImageRugby, path: "/rugby" },
  { name: "Squash", image: ImageSquash, path: "/squash" },
  { name: "Tae Kwon Do", image: ImageTaeKwonDo, path: "/taekwondo" },
  { name: "Tennis", image: ImageTennis, path: "/tennis" },
  {
    name: "Tennis de table",
    image: ImageTennisDeTable,
    path: "/tennisdetable",
  },
  { name: "Volleyball", image: ImageVolleyball, path: "/volleyball" },
  { name: "Yoga", image: ImageYoga, path: "/yoga" },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sports.length);
    }, 4000); // Change toutes les 4 secondes

    return () => clearInterval(interval);
  }, []);

  // Gère l'affichage des 3 images visibles
  const getVisibleSports = () => {
    const prevIndex = (currentIndex - 1 + sports.length) % sports.length;
    const nextIndex = (currentIndex + 1) % sports.length;
    return [sports[prevIndex], sports[currentIndex], sports[nextIndex]];
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        {getVisibleSports().map((sport, index) => (
          <Link
            key={index}
            to={sport.path}
            className={`card ${index === 1 ? "active" : ""}`}
          >
            <img src={sport.image} alt={sport.name} />
            {index === 1 && <div className="sport-name">{sport.name}</div>}{" "}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
