import React, { useState, useEffect } from "react";
import "../styles/bandeauAccueil.css";
import athletisme from "../images/Accueil/imagesBandeau/athletisme.webp";
import basketball from "../images/Accueil/imagesBandeau/basketball.webp";
import boxe from "../images/Accueil/imagesBandeau/boxe.webp";
import natation from "../images/Accueil/imagesBandeau/natation.webp";
import rugby from "../images/Accueil/imagesBandeau/rugby.webp";
import tennis from "../images/Accueil/imagesBandeau/tennis.webp";
import yoga from "../images/Accueil/imagesBandeau/yoga.webp";

const BandeauAccueil = () => {
  const images = [athletisme, basketball, boxe, natation, rugby, tennis, yoga];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  // Change d'image toutes les 3 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Démarre l'effet de disparition

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true); // Rétablit l'effet d'apparition
      }, 400);
    }, 4000);

    return () => clearInterval(interval); // Nettoyage à la fin
  }, [images.length]);

  return (
    <div className="image-banner">
      <img
        src={images[currentIndex]}
        alt="Bandeau"
        className={`image ${fade ? "fade-in" : "fade-out"}`}
      />
    </div>
  );
};

export default BandeauAccueil;
