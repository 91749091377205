import React, { useState, useEffect } from "react";
import "../styles/historySection.css";

const HistorySection = ({ sections, members, years }) => {
  const [sectionCount, setSectionCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [yearCount, setYearCount] = useState(0);

  // Animation des chiffres (compteurs)
  useEffect(() => {
    const animateCount = (target, setter) => {
      let start = 0;
      const duration = 3500;
      const incrementStep = Math.ceil(target / (duration / 35));
      const interval = setInterval(() => {
        start += incrementStep;
        if (start >= target) {
          start = target;
          clearInterval(interval);
        }
        setter(start);
      }, 35);
    };

    animateCount(sections, setSectionCount);
    animateCount(members, setMemberCount);
    animateCount(years, setYearCount);
  }, [sections, members, years]);

  return (
    <div className="history-section">
      <div className="numbers-container">
        <div className="number-box-sections">
          <h1 className="counter">{isNaN(sectionCount) ? 0 : sectionCount}</h1>
          <b className="b-style">Sections sportives</b>
          <p className="p-historySection">
            Aujourd'hui, notre association regroupe 27 sections sportives,
            offrant une diversité d'activités pour tous les âges et tous les
            niveaux.
          </p>
        </div>
        <div className="number-box-years">
          <h1 className="counter">{isNaN(yearCount) ? 0 : yearCount}</h1>
          <b className="b-style">Années d'existence</b>
          <p className="p-historySection">
            Nous avons grandi et évolué pour devenir un véritable pilier du
            sport dans notre région.
          </p>
        </div>

        <div className="number-box-members">
          <h1 className="counter">{isNaN(memberCount) ? 0 : memberCount}</h1>
          <b className="b-style">Adhérents</b>
          <p className="p-historySection">
            Avec nos 3 800 adhérents passionnés, nous sommes plus qu'une simple
            organisation sportive : nous formons une véritable communauté, unie
            autour des valeurs de respect, de dépassement de soi et de
            convivialité.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HistorySection;
